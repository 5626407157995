import Image from 'next/image'
import { Grid } from '@mui/material'

// Types
import type { LogoProps } from '@/types/components/atoms/shapes/logo'

const LogoAtom = (props: LogoProps) => {
	// Props
	const { width, height, sx } = props

	return (
		<Grid width={width} height={height} sx={sx}>
			<Image src="/images/theme/logo.svg" alt="Regiti Logo" width={width} height={height} />
		</Grid>
	)
}

export default LogoAtom
